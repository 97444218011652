import React from 'react';
//import ReactDOM from 'react-dom';
import Editor ,{ composeDecorators } from 'draft-js-plugins-editor';
import createLinkPlugin from 'draft-js-anchor-plugin';
import createAlignmentPlugin from 'draft-js-alignment-plugin';
//import {EditorState} from 'draft-js';
import {convertFromRaw,
        EditorState} from 'draft-js';

//style css
//import linkStyles from './linkStyles.css';
import createColorBlockPlugin from './colorBlockPlugin';

/*var start = {
  blocks: [
    {
      key: "9gm3s",
      text: "Inizia a scrivere l'articolo",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    }
  ],
  entityMap: {}
};*/

const alignmentPlugin = createAlignmentPlugin();

const decorator = composeDecorators(
  alignmentPlugin.decorator
);

const colorBlockPlugin = createColorBlockPlugin({ decorator });

const linkPlugin = createLinkPlugin({
  theme: {},
  placeholder: 'http://…'
});

const plugins = [
  colorBlockPlugin,
  alignmentPlugin,
  linkPlugin
];

class MyEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {editorState: EditorState.createWithContent(convertFromRaw(this.props.body))};
    this.onChange = (editorState) => {
      this.setState({editorState});
      //console.log(this.state.editorState);
    };
  }

  render() {
    return (
      <div>
        <Editor
        readOnly={true}
        blockStyleFn={{}}
        editorState={this.state.editorState}
        onChange={this.onChange}
        plugins={plugins}
      />
      </div>

    );
  }
}


export default MyEditor
