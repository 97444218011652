import React from 'react';
import { Link } from 'gatsby';
import Editor  from "../components/Editor";
import Layout from "../components/layout"


function prova(e,b,c){

  var magic = document.getElementById("magic");
  console.log('magiiicooo');
  console.log(e.pageY);
  if(e.pageY<400){
    document.getElementById("magic").style.left = (e.pageX-230)+'px';
    document.getElementById("magic").style.top = (e.pageY-230)+'px';
  }

  //$magic.css({"left": e.pageX - magicWHalf, "top": e.pageY - magicWHalf});

}




export default ({ pageContext: { room,lang } }) => (
  <Layout lang={lang}>
    <div>



      {/*<section id='imgBig' style={{display: 'table',backgroundImage: 'linear-gradient(0deg,rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)),url('+room.copertina+')', height: 400, width: '100%'}}>
        <div style={{verticalAlign: 'middle',display: 'table-cell'}}>
          <div id='testoImgBig'>
            <h1>{room.title}</h1>
            <p>sub</p>
          </div>
        </div>
      </section>*/}


      <div style={{ maxWidth: 770, margin: '4rem auto', marginTop: 161,background: '#ffffff',position: 'relative' }}>
        <Editor readOnly={true} body={ lang === 'en' ? JSON.parse(room.bodyen) : JSON.parse(room.body)}/>
      </div>
    </div>
  </Layout>
);
