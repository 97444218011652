import React from 'react';
//import MediaServicesArrowIcon from '@atlaskit/icon/glyph/media-services/arrow';
//import Embedly from 'react-embedly';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

function vimeoUrl(url){
    //var val = document.getElementById('video').value;
    var vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
    var parsed = url.match(vimeoRegex);

    return "//player.vimeo.com/video/" + parsed[1];
};


function getId(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length === 11) {
        return match[2];
    } else {
        return 'error';
    }
}

function carosell(array){
  return array.map(function(item, i){
    return(
      <div key={i}>
          <img alt='' src={item} />
      </div>
    )
  })
}

function galleryCreate(array){
  return array.map(function(item, i){
    console.log('test');
    return <div style={{flex: '1 0 300px',boxSizing: 'border-box',marginLeft: '5px',marginTop: '5px',display:'flex',alignItems: 'center',background: '#f4f4f49c'}} key={i}><img alt='' src={item} /></div>
  })
}

const GalleryBlock = ({
  block, // eslint-disable-line no-unused-vars
  blockProps, // eslint-disable-line no-unused-vars
  customStyleMap, // eslint-disable-line no-unused-vars
  customStyleFn, // eslint-disable-line no-unused-vars
  decorator, // eslint-disable-line no-unused-vars
  forceSelection, // eslint-disable-line no-unused-vars
  offsetKey, // eslint-disable-line no-unused-vars
  selection, // eslint-disable-line no-unused-vars
  tree, // eslint-disable-line no-unused-vars
  contentState, // eslint-disable-line no-unused-vars
  style,
  ...elementProps
}) => {

  var imgGallery = []
  //var url = ''
  //var altImgP = ''
  var alignmentx = ''


  if(contentState){
    const entity = contentState.getEntity(block.getEntityAt(0));
  	const { imgs,alignment } = entity.getData();

    //url = src;
    //altImgP = altImg;
    imgGallery = imgs;
    alignmentx = alignment;
    console.log('questo è alll');
    console.log(alignment);

  }


  let width = '100%';
  let height = 'auto';
  //let videoHeight = '350px';
  let marginRight = 0;
  if(!style){

  }else{
    width= 200;
    height = 'auto';
    //videoHeight = '110px';
    marginRight = '16px';
  }

  if(alignmentx === 'griglia'){
    return(
      <div
        {...elementProps}
        className='imgGallery'
        style={ alignmentx === 'griglia' ? {display:'flex',flexWrap: 'wrap', height: height,marginRight:marginRight, ...style } : {width: width, height: height,marginRight:marginRight, ...style}}
      >
      {galleryCreate(imgGallery)}
      </div>
      );
  }else{
    return(
      <div {...elementProps} style={ alignmentx === 'griglia' ? {display:'flex', width: width, height: height,marginRight:marginRight, ...style } : {width: width, height: height,marginRight:marginRight,marginTop: '-10px', ...style}}>
        <Carousel showThumbs={false} dynamicHeight width="100%" autoPlay infiniteLoop>
            {carosell(imgGallery)}
        </Carousel>
      </div>
    )
  }

}


const HrBlock = ({
  block, // eslint-disable-line no-unused-vars
  blockProps, // eslint-disable-line no-unused-vars
  customStyleMap, // eslint-disable-line no-unused-vars
  customStyleFn, // eslint-disable-line no-unused-vars
  decorator, // eslint-disable-line no-unused-vars
  forceSelection, // eslint-disable-line no-unused-vars
  offsetKey, // eslint-disable-line no-unused-vars
  selection, // eslint-disable-line no-unused-vars
  tree, // eslint-disable-line no-unused-vars
  contentState, // eslint-disable-line no-unused-vars
  style,
  ...elementProps
}) => {


  //var url = ''
  //var altImgP = ''

  return(
    <div
      {...elementProps}
    >
    <hr></hr>

  </div>
    );




  }


const EmbedBlock = ({
  block, // eslint-disable-line no-unused-vars
  blockProps, // eslint-disable-line no-unused-vars
  customStyleMap, // eslint-disable-line no-unused-vars
  customStyleFn, // eslint-disable-line no-unused-vars
  decorator, // eslint-disable-line no-unused-vars
  forceSelection, // eslint-disable-line no-unused-vars
  offsetKey, // eslint-disable-line no-unused-vars
  selection, // eslint-disable-line no-unused-vars
  tree, // eslint-disable-line no-unused-vars
  contentState, // eslint-disable-line no-unused-vars
  style,
  ...elementProps
}) => {


  var url = ''
  //var altImgP = ''


  if(contentState){
    const entity = contentState.getEntity(block.getEntityAt(0));
  	const { src } = entity.getData();

    url = src;
    //altImgP = altImg;
  }


  //let width = '100%';
  //let height = 'auto';
  //let videoHeight = '350px';
  //let marginRight = 0;
  if(!style){

  }else{
    //width= 200;
    //height = 'auto';
    //videoHeight = '110px';
    //marginRight = '16px';
  }


  if(url.indexOf("open.spotify.com") >= 0){
    //var videoId = getId(url);
    /*
    var iframeMarkup = '<iframe width="560" height="315" src="//www.youtube.com/embed/'
        + videoId + '" frameborder="0" allowfullscreen></iframe>';*/
    var words = url.split('?');
    let ok = words[0];
    let newUrl = ok.split('/')
    console.log('mostrooo');
    console.log(newUrl);
    switch (newUrl[3]) {
      case 'track':
      return (
        <div
          {...elementProps}
          >
          <iframe title={newUrl[4]}  src={"https://open.spotify.com/embed/track/"+newUrl[4]} width="100%" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
        </div>
      )
      //break;
      case 'album':
      return (
        <div
          {...elementProps}
          >
          <iframe title={newUrl[4]} src={"https://open.spotify.com/embed/album/"+newUrl[4]} width="100%" height="280" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
        </div>
      )
        //break;
      default:
      return (
        <div
          {...elementProps}
          >
          <iframe title={newUrl[4]} src={"https://open.spotify.com/embed-podcast/episode/"+newUrl[4]} width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
        </div>
      )

    }
  }else{
    return(
      <div
        {...elementProps}
      >
      <blockquote style={{background:'white'}} class="embedly-card" data-card-key="e14e16cf6a584573989536ecff8fda8d" data-card-controls="0" data-card-width="100%"><h4><a href={url}></a></h4><p></p></blockquote>

    </div>
      );
  }






}


const ColorBlock = ({
  block, // eslint-disable-line no-unused-vars
  blockProps, // eslint-disable-line no-unused-vars
  customStyleMap, // eslint-disable-line no-unused-vars
  customStyleFn, // eslint-disable-line no-unused-vars
  decorator, // eslint-disable-line no-unused-vars
  forceSelection, // eslint-disable-line no-unused-vars
  offsetKey, // eslint-disable-line no-unused-vars
  selection, // eslint-disable-line no-unused-vars
  tree, // eslint-disable-line no-unused-vars
  contentState, // eslint-disable-line no-unused-vars
  style,
  ...elementProps
}) => {



  var url = ''
  var altImgP = ''


  if(contentState){
    const entity = contentState.getEntity(block.getEntityAt(0));
  	const { src,altImg } = entity.getData();

    url = src;
    altImgP = altImg;
  }


  let width = '100%';
  let height = 'auto';
  let videoHeight = '350px';
  let marginRight = 0;
  if(!style){

  }else{
    width= 319;
    height = 'auto';
    videoHeight = '110px';
    marginRight = '16px';
  }

  if(url.indexOf("youtube.com") >= 0){
    var videoId = getId(url);

    /*var iframeMarkup = '<iframe title="youtube" width="560" height="315" src="//www.youtube.com/embed/'
        + videoId + '" frameborder="0" allowfullscreen></iframe>';*/
    return (
      <div
        {...elementProps}
        style={{ width: width, height: height,marginRight:marginRight, ...style }}>
        {/*<p style={{position: 'absolute',background: 'white',padding: 5,margin: 0,cursor: 'move',color: 'white'}}><MediaServicesArrowIcon primaryColor='black'/></p>
      */}<iframe title={videoId}  width="100%" height={videoHeight} src={'//www.youtube.com/embed/'+videoId} frameborder="0" allowfullscreen></iframe>

      </div>
    )
  }if(url.indexOf("vimeo.com") >= 0){
    var videoUrl = vimeoUrl(url);


    return (
      <div
        {...elementProps}
        style={{ width: width, height: height,marginRight:marginRight, ...style }}>
        <p style={{position: 'absolute',background: 'white',padding: 5,margin: 0,cursor: 'move',color: 'white'}}></p>
          <iframe title={videoUrl}  src={videoUrl} width="100%" height={videoHeight} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>

      </div>
    )
  }else{
    return(
      <div
        {...elementProps}
        //className={ style.float && style.float == 'right' ? 'destra' : 'sinistra'}
        style={{ width: width, height: height,marginRight:marginRight,marginLeft:16, ...style }}
      ><img alt={altImgP}src={url} /></div>
      );
  }



}





const createColorBlockPlugin = (config = {}) => {

  const component = config.decorator ? config.decorator(ColorBlock) : ColorBlock;
  const componentNormal =  EmbedBlock;
  const componentGallery = config.decoratorNew ? config.decoratorNew(GalleryBlock) : GalleryBlock;
  const componentHr = HrBlock;

  return {
    blockRendererFn: (block, { getEditorState }) => {
      if (block.getType() === 'atomic') {
        const contentState = getEditorState().getCurrentContent();
        const entity = contentState.getEntity(block.getEntityAt(0));
        //const { src } = entity.getData();
        const type = entity.getType();
        if (type === 'IMAGE') {
          return {
            component,
            editable: false,
          };
        }
        if (type === 'VIDEO') {
          return {
            component,
            editable: false,
          };
        }
        if (type === 'EMBED') {
          return {
            component:componentNormal,
            editable: false,
          };
        }
        if (type === 'galleryImg') {
          return {
            component:componentGallery,
            editable: true,
          };
        }
        if (type === 'HR') {
          return {
            component:componentHr,
            editable: false,
          };
        }
      }
      return null;
    },
  };
};

export default createColorBlockPlugin;
